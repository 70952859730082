import React from 'react';
import loadable from '@loadable/component';
import { withRouter } from 'react-router-dom';

import { bool, object, shape, func, array } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { isMainSearchTypeKeywords, isOriginInUse } from '../../util/search';
import { createResourceLocatorString } from '../../util/routes';
import { getListingsById } from '../../ducks/marketplaceData.duck';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

export const LandingPageComponent = props => {
  const {
    pageAssetsData,
    inProgress,
    error,
    currentSearchParams,
    history,
    config,
    routeConfiguration,
    featured_listings,
    latest_listings,
    promoted_listings,
    curated_carousel_listings,
    curated_stack_listings,
    pollinator_listings,
    small_space_listings,
    unique_listings,
    on_sale_listings,
  } = props;

  const current_config = useConfiguration();
  const current_routeConfiguration = useRouteConfiguration();

  const handleSearchSubmit = (values) => {
    // console.log('search values', values, current_config);
    const topbarSearchParams = () => {
      if (isMainSearchTypeKeywords(current_config)) {
        return { keywords: values?.keywords };
      }
      // topbar search defaults to 'location' search
      // const { search, selectedPlace } = values?.location;
      const { origin, bounds } = selectedPlace;
      const originMaybe = isOriginInUse(current_config) ? { origin } : {};

      return {
        ...originMaybe,
        address: search,
        bounds,
      };
    };
    const searchParams = {
      ...currentSearchParams,
      ...topbarSearchParams(),
    };
    history.push(createResourceLocatorString('SearchPage', current_routeConfiguration, {}, searchParams));
  }

  return (
    <div>
      <PageBuilder
        pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
        inProgress={inProgress}
        error={error}
        fallbackPage={<FallbackPage error={error} />}
        onSearchSubmit={handleSearchSubmit}
        featured_listings={featured_listings}
        latest_listings={latest_listings}
        promoted_listings={promoted_listings}
        curated_carousel_listings={curated_carousel_listings}
        curated_stack_listings={curated_stack_listings}
        pollinator_listings={pollinator_listings}
        small_space_listings={small_space_listings}
        unique_listings={unique_listings}
        on_sale_listings={on_sale_listings}
      />
    </div>
    
  );
};

LandingPageComponent.defaultProps = {
  featured_listings: [],
  latest_listings: [],
  promoted_listings: [],
  curated_carousel_listings: [],
  curated_stack_listings: [],
  pollinator_listings: [],
  small_space_listings: [],
  unique_listings: [],
  on_sale_listings: [],
  fetchListingsInProgress: false,
  fetchListingsListingsError: null,
}

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  featured_listings: array,
  latest_listings: array,
  promoted_listings: array,
  curated_carousel_listings: array,
  curated_stack_listings: array,
  pollinator_listings: array,
  small_space_listings: array,
  unique_listings: array,
  on_sale_listings: array,
  fetchListingsInProgress: bool,
  fetchListingsListingsError: propTypes.error,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    latestPageResultIds,
    promotedPageResultIds,
    curatedCarouselPageResultIds,
    curatedStackPageResultIds,
    pollinatorPageResultIds,
    smallSpacePageResultIds,
    uniquePageResultIds,
    onSalePageResultIds,
    fetchListingsInProgress,
    fetchListingsListingsError,
  } = state.LandingPage;
  const featured_listings = getListingsById(state, currentPageResultIds);
  const latest_listings = getListingsById(state, latestPageResultIds);
  const promoted_listings = getListingsById(state, promotedPageResultIds);
  const curated_carousel_listings = getListingsById(state, curatedCarouselPageResultIds);
  const curated_stack_listings = getListingsById(state, curatedStackPageResultIds);
  const pollinator_listings = getListingsById(state, pollinatorPageResultIds);
  const small_space_listings = getListingsById(state, smallSpacePageResultIds);
  const unique_listings = getListingsById(state, uniquePageResultIds);
  const on_sale_listings = getListingsById(state, onSalePageResultIds);
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return {
    pageAssetsData,
    inProgress,
    error,
    currentPageResultIds,
    featured_listings,
    latest_listings,
    promoted_listings,
    curated_carousel_listings,
    curated_stack_listings,
    pollinator_listings,
    small_space_listings,
    unique_listings,
    on_sale_listings,
    fetchListingsInProgress,
    fetchListingsListingsError,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps),
)(LandingPageComponent);

export default LandingPage;
